<template>
  <div class="text-center text-h5 primary--text">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "DashboardText",
  props: {
    text: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style scoped></style>
